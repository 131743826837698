<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from 'quill-blot-formatter'
import { ImageDrop } from 'quill-image-drop-module'
import DatePicker from "@vuepic/vue-datepicker";

export default {
  components: {
    Layout,
    PageHeader,
    QuillEditor,
    DatePicker
  },
  setup: () => {
    const blotModule = {
      name: 'blotFormatter',
      module: BlotFormatter,
      options: {/* options */}
    }

    const dropModule = {
      name: 'imageDrop',
      module: ImageDrop
    }
    return {blotModule, dropModule}
  },
  data() {
    return {
      title: this.$t('점검') + this.$t('설정'),
      options: {
        debug: 'error',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'image']
        },
        placeholder: '내용을 입력하세요...',
        readOnly: false,
        theme: 'snow'
      },
      toolbarOptions: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      form: {
        json: {
          active: false,
          desc: '',
          untilTime: null
        }
      }
    }
  },
  mounted() {
    this.get().then()
  },
  methods: {
    get: async function() {
      const result = await this.$API.setting.getSetting('server', 'maintenance')
      if (result.code !== 0) {
        return alert(result.msg)
      }

      this.form.json.desc = result.document.json.desc
      if (result.document.json.untilTime) {
        this.form.json.untilTime = new Date(result.document.json.untilTime)
      }
      this.form.json.active = result.document.json.active
      this.editor.setHTML(this.form.json.desc)
    },
    save: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.setting.updateSetting('server', 'maintenance', this.form)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.$emit('searchList')
        this.openDialog = false
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row" ref="contentWrapper">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="save">
              <div class="form-group mb-3">
                <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                  <input class="form-check-input" type="checkbox" v-model="form.json.active">
                  <label class="form-check-label">{{ $t('점검 설정') }}</label>
                </div>
              </div>
              <div class="form-group mb-3">
                <date-picker
                    v-model="form.json.untilTime"
                    :format="'yyyy-MM-dd HH:mm:ss'"
                    utc
                    :placeholder="$t('점검 종료일 설정')"
                />
              </div>
              <div class='form-group'>
                <QuillEditor ref="quillEditor" style="height: 300px;" :toolbar="toolbarOptions" :options="options" :modules="[blotModule, dropModule]" v-model:content="form.json.desc" content-type="html" />
              </div>
              <div class='text-end'>
                <hr />
                <button type='submit' class='btn btn-primary me-1' @click='save'>{{ $t('저장') }}</button>
                <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
